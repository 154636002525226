import type { FormInstance } from 'antd';
import { message } from 'antd';
import { action, observable } from 'mobx';
import type { CommonData } from '../../../../utils';
import { request } from '../../../../utils';

export default class QrcodeEditorModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable
  public visible = false;

  @observable
  public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public parent = null;

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = () => {
    this.visible = true;
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    request<CommonData<any>>({
      url: '/quan/back/promotion/add',
      method: 'POST',
      data: values,
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onClose();
        this.parent.pageStore?.grid?.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
