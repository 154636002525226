import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Model from './model';

const { TextArea } = Input;

// 新增或编辑需求配置信息
const QrcodeEdit = observer(({ store }: { store: Model }): React.ReactElement => {
  if (!store) {
    return null;
  }
  const { visible, parent, saveLoading, onSave, onClose, setRef } = store;
  return (
    <Modal
      centered
      onOk={onSave}
      onClose={onClose}
      confirmLoading={saveLoading}
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      title="生成二维码"
      okText="生成"
    >
      <Form
        ref={setRef}
        labelCol={{ span: 4 }}
      >
        <Form.Item
          label="姓名"
          name="nickName"
          rules={[{ required: true }]}
          validateTrigger="onBlur"
        >
          <Input
            style={{ width: '100%' }}
            autoComplete="off"
            placeholder="请输入"
            maxLength={50}
            showCount
          />
        </Form.Item>
        <Form.Item
          label="手机号"
          name="mobile"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确的手机号',
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder="请输入"
            maxLength={11}
          />
        </Form.Item>
        <Form.Item
          label="渠道"
          name="channelValue"
          validateTrigger="onChange"
          rules={[
            {
              required: true,
              message: '请选择渠道',
            },
          ]}
        >
          <Select
            options={parent.channelOptions}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item
          label="备注"
          name="remark"
        >
          <TextArea
            placeholder="请输入"
            autoSize={{
              minRows: 3,
              maxRows: 6,
            }}
            maxLength={100}
            showCount
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default QrcodeEdit;
