import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { SearchListStructure } from '../../utils/searchListStructure';
import PartTimeModal from './modal/qrcodeEdit';
import Model from './model';

const QrCodeManagement: React.FC = () => {
  const store = useMemo(() => new Model(), []);
  const { pageStore, editStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <PartTimeModal store={editStore} />
    </div>
  );
};
export default observer(QrCodeManagement);
