import { Button, Image, Modal, Tooltip } from 'antd';
import { action, observable } from 'mobx';
import React from 'react';
import type { CommonData, DictData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { RenderByPermission, request, SearchListModal } from '../../utils';
import QrcodeEditorModel from './modal/qrcodeEdit/model';

export default class Model {
  constructor() {
    this.getDictOptions('channel_type');
    this.pageStore.grid.onQuery();
  }

  public editStore = new QrcodeEditorModel({ parent: this });

  @observable public channelOptions = []; //渠道列表

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'nickName',
        label: '姓名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'mobile',
        label: '手机号',
        placeholder: '请输入',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '生成时间',
        format: 'YYYY-MM-DD HH:mm:ss',
        formatParams: 'YYYY-MM-DD HH:mm:ss',
        span: 6,
      },
      {
        type: 'select',
        field: 'channelValue',
        label: '渠道',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        type: 'primary',
        permissionId: '2',
        text: '生成二维码',
        handleClick: () => {
          this.editStore.onShow();
        },
      },
    ],
    grid: {
      rowHeight: 100,
      columns: [
        {
          key: 'userId',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_83`}>
                  <Button
                    onClick={() => {
                      this.downloadQrCode(row);
                    }}
                    type="link"
                  >
                    下载
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'id',
          name: 'ID',
        },
        {
          key: 'nickName',
          name: '姓名',
          formatter: ({ row }) =>
            row?.nickName?.length > 10 ? (
              <Tooltip title={row.nickName}>
                <span className="cursor-pointer">{`${row.nickName.substring(0, 10)}...`}</span>
              </Tooltip>
            ) : (
              row?.nickName
            ),
        },
        {
          key: 'mobile',
          name: '手机号',
        },
        {
          key: 'createTime',
          name: '生成时间',
        },
        {
          key: 'qrCode',
          name: '二维码',
          formatter: ({ row }) =>
            row.url ? (
              <div className="h-full flex items-center">
                <Image
                  src={row.url}
                  width={80}
                  height={80}
                />
              </div>
            ) : (
              '暂无二维码'
            ),
        },
        {
          key: 'channelName',
          name: '渠道',
        },
        {
          key: 'remark',
          name: '备注',
          formatter: ({ row }) =>
            row?.remark?.length > 10 ? (
              <Tooltip title={row.remark}>
                <span className="cursor-pointer">{`${row.remark.substring(0, 10)}...`}</span>
              </Tooltip>
            ) : (
              row?.remark
            ),
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showSelectedTotal: false,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.startTime = postParams.createTime.split(',')[0];
          postParams.endTime = postParams.createTime.split(',')[1];
          delete postParams.createTime;
        }
        return request<CommonData<any>>({
          url: '/quan/back/promotion/list',
          method: 'POST',
          data: { ...postParams },
        }).then((res) => res);
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 下载
  public downloadQrCode = (row: any) => {
    if (!row.url) {
      return;
    }
    window.location.href = row.url;
  };

  // 查询字典数据
  @action
  public getDictOptions = async (dictType: string) => {
    const req = await request<CommonData<DictData[]>>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType },
    });
    const data = (req?.data || [])?.map((item) => ({
      label: item.dictName,
      value: item.dictValue,
    }));
    switch (dictType) {
      case 'channel_type': {
        this.channelOptions = data;
        this.pageStore.programme.filterItems.addDict({ channelValue: data });
        break;
      }
    }
  };
}
